import classNames from "classnames"
import React from "react"

import { BottomEdgeLine } from "../common/Decorations"
import Img from "../common/Img"
import s from "./ImageHero.module.scss"

export default function ImageHero({
  className,
  imageUrl,
  alt,
  showBottomEdgeLine = false,
}) {
  return (
    <div className={classNames(s.imageHero, className)}>
      <Img alt={alt} loading="eager" src={imageUrl} />
      {showBottomEdgeLine ? <BottomEdgeLine /> : null}
    </div>
  )
}
