import { graphql } from "gatsby"
import React from "react"

import ImageHero from "../components/ImageHero/ImageHero"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import {
  selectFeedbackSuccessPageContent,
  selectProductGroups,
  selectTranslations,
} from "../graphql/selectors"
import s from "./feedback-success.module.scss"

export default function FeedbackSuccessPage(props) {
  const { data, pageContext } = props
  const { langCode, localePath, pagePathsByLangCode } = pageContext
  const translations = selectTranslations(data)
  const { seo, hero, successText } = selectFeedbackSuccessPageContent(data)

  return (
    <Layout
      className={s.feedbackSuccessPage}
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO {...seo} lang={langCode} />
      <ImageHero alt={hero.alt} className={s.hero} imageUrl={hero.imageUrl} />
      <div className={s.container}>
        <div className={s.successText}>{successText}</div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query FeedbackSuccessPage($locale: String) {
    ...FeedbackSuccessPageFragment
    ...LayoutFragment
    ...TranslationsFragment
  }
`
